<template>
  <div class="base-box">
    <UploadInfoCom @uploadSuccess="uploadSuccessCallBack" :options="options" />
    <SearchInput
      :placeHolder="placeHolder"
      @searchInput="searchInput"
    />
    <SwitchTable
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo"
    />
    <TableList
      v-if="showCom"
      :dataList="dataList"
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      :listType="listType"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
    />
  </div>
</template>

<script>
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "agreeMentMove",
  data() {
    return{
      placeHolder: '请输入补充协议名称、主合同名称、经办人、签约主体关键词进行搜索',
      dataList: [],
      asyncInfo: {}, //切换组件数据
      total: 0,
      pageNum: 1,
      pageSize: 10,
      companyName: '',
      listType: '0',
      showCom: true,
      options: [
        {
          value: 'COST',
          label: '老成本'
        }
      ],
      currentData: {}
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    uploadSuccessCallBack() {
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      this.listType = val === 'Y' ? '1' : '0'
      this.init()
    },
    // 删除
    deleteDMAgreeMent() {
      this.$request.get(this.URL.deleteDMAgreeMent + '?id=' + this.currentData.id).then(res => {
        if (res.code === '200') {
          this.$message.success(res.data.result)
          this.init()
        }
      })
    },
    // 取消同步
    unSyncAgreeMent() {
      this.$request.get(this.URL.unSyncAgreeMent + '?id=' + this.currentData.id).then(res => {
        if (res.code === '200') {
          this.init()
        }
      })
    },
    // 列表操作
    operate(type, obj) {
      this.currentData = obj
      if (type === 'delete') {
        this.deleteDMAgreeMent(obj.id)
      } else if (type === 'cancel') {
        // 取消同步
        this.unSyncAgreeMent()
      }
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      // this.init()
      this.getAgreeMentList()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.companyName = val
      this.init()
    },
    // 查询列表数据
    getAgreeMentList() {
      // 1-已同步 0-未同步
      var obj = {
        params: {
          name: this.companyName,
          type: this.listType,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      this.$request.post(this.URL.agreeMentMoveList, obj).then(res => {
        this.showCom = true
        if (res.code === '200') {
          this.total = Number(res.data.agreeMentInfo.total)
          var obj = {unsynchronized: '0', synchronization: '0'}
          if (this.listType === '1') {
            // 同步
            obj.synchronization = this.total
            obj.unsynchronized = res.data.count - this.total
          } else {
            // 未同步
            obj.unsynchronized = this.total
            obj.synchronization = res.data.count - this.total
          }
          this.asyncInfo = obj
          this.dataList = res.data.agreeMentInfo.list
        }
      })
    },
    init() {
      this.pageNum = 1
      this.getAgreeMentList()
    }
  },
  mounted() {
    this.init()
  },
  created() {
    
  }
};
</script>
<style lang="less" scoped>
</style>